import React, { useEffect } from "react";
import "./TermsOfUse.css";
import NavbarLanding from "../../Components/NavbarLanding/NavbarLanding";
import Footer from "../Footer/Footer";
import { ToastContainer } from "react-toastify";

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms-page">
      {/* Navbar with full width */}
      <NavbarLanding />

      {/* Terms content container */}
      <div className="terms-container">
        <h1>Terms of Use</h1>

        <h2>1. Introduction</h2>
        <p>
          1.1 This website (“our Site”) promotes the business referred to on it.
          In these Terms of Use (“Terms”), the business will be referred to as
          ‘we’, ‘us’ or ‘our’.
        </p>
        <p>
          1.2 You should read these Terms carefully before using the Site. By
          using the Site or otherwise indicating your consent, you agree to be
          bound by these Terms. If you do not agree with any of these Terms, you
          should stop using the Site immediately. These Terms should be read in
          conjunction with any details provided on our Site about how our Site
          operates and the services which are available, including our Privacy &
          Cookie Policy and Trading Terms (if applicable).
        </p>
        <p>
          1.3 If you order any goods, services, or digital content from the Site
          or if you buy a subscription or membership to access restricted parts
          of the Site, separate terms and conditions may apply.
        </p>
        <p>
          1.4 We reserve the right to change these Terms of Use from time to
          time without prior notice by changing them on our Site.
        </p>

        <h2>2. Using our Site</h2>

        <p>
          2.1 You agree that you are solely responsible for all costs and
          expenses you may incur in relation to your use of the Site.
        </p>

        <p>
          2.2 We make no promise that the Site is appropriate or available for
          use in locations outside of the UK. If you choose to access the Site
          from locations outside the UK, you acknowledge you do so at your own
          initiative and are responsible for compliance with local laws where
          they apply.
        </p>

        <p>
          2.3 We try to make the Site as accessible as possible. If you have any
          difficulties using the Site, please contact us using the contact
          details at the top of this page.
        </p>

        <p>2.4 You agree not to use our Site:</p>

        <p style={{ marginLeft: "20px" }}>
          a. for any unlawful, immoral, or anti-social purpose;
        </p>
        <p style={{ marginLeft: "20px" }}>
          b. to send spam; nuisance or any other unwanted calls;
        </p>
        <p style={{ marginLeft: "20px" }}>
          c. for any kind of direct marketing activity;
        </p>
        <p style={{ marginLeft: "20px" }}>
          d. to harm, threaten, abuse or harass another person, or in a way that
          invades someone’s privacy or is (in our reasonable opinion) offensive
          or is unacceptable or damaging to us, our customers or suppliers;
        </p>
        <p style={{ marginLeft: "20px" }}>
          e. to create, check, confirm, update or amend your own or someone
          else’s databases, records, directories, customer lists, mailing or
          prospecting lists;
        </p>
        <p style={{ marginLeft: "20px" }}>
          f. to tamper with, update or change any part of our Site;
        </p>
        <p style={{ marginLeft: "20px" }}>
          g. in a way that affects how it is run;
        </p>
        <p style={{ marginLeft: "20px" }}>
          h. in a way that imposes an unreasonable or disproportionably large
          burden on us or our suppliers’ communications and technical systems as
          determined by us; or
        </p>
        <p style={{ marginLeft: "20px" }}>
          i. using any automated means to monitor or copy our Site or its
          content, or to interfere with or attempt to interfere with how our
          Site works.
        </p>

        <p>
          2.5 We may prevent or suspend your access to the Site if you do not
          comply with these Terms or any applicable law.
        </p>

        <h2>3. Your Privacy and Personal Information</h2>
        <p>
          3.1 Your privacy and personal information are important to us. Any
          personal information that you provide to us will be dealt with in line
          with our Privacy & Cookie Policy, which explains what personal
          information we collect from you, how and why we collect, store, use
          and share such information, your rights in relation to your personal
          information and how to contact us and supervisory authorities in the
          event you have a query or complaint about the use of your personal
          information.
        </p>

        <h2>4. Ownership, Use, and Intellectual Property Rights</h2>
        <p>
          4.1 The intellectual property rights in the Site and in any text,
          images, video, audio or other multimedia content, software or other
          information or material submitted to or accessible from the Site
          (Content) are owned by us and our licensors.
        </p>

        <p>
          4.2 We and our licensors reserve all our intellectual property rights
          (including, but not limited to, all copyright, trademarks, domain
          names, design rights, database rights, patents, and all other
          intellectual property rights of any kind) whether registered or
          unregistered anywhere in the world. This means, for example, that we
          remain owners of them and are free to use them as we see fit.
        </p>

        <p>
          4.3 Nothing in these Terms grants you any legal rights in the Site or
          the Content other than as necessary for you to access it. You agree
          not to adjust, try to circumvent, or delete any notices contained on
          the Site or the Content (including any intellectual property notices)
          and in particular, in any digital rights or other security technology
          embedded or contained within the Site or the Content.{" "}
        </p>

        <p>
          4.4 Use by you of any trademarks on the Site or in the Content is
          strictly prohibited unless you have our prior written consent.
        </p>

        <p>
          4.5 If you believe that any content which is distributed or published
          by the Site is inappropriate, defamatory, or infringing on
          intellectual property rights, you should contact us immediately using
          the contact details at the top of this page.
        </p>

        <h2>5. Submitting Information to the Site</h2>
        <p>
          5.1 While we try to make sure that the Site is secure, we do not
          actively monitor or check whether information supplied to us through
          the Site is confidential, commercially sensitive, or valuable.
        </p>

        <p>
          5.2 Other than any personal information which will be dealt with in
          accordance with our Privacy & Cookie Policy, we do not guarantee that
          information supplied to us through the Site will be kept confidential
          and we may use it on an unrestricted and free-of-charge basis as we
          reasonably see fit.
        </p>

        <h2>6. Hyperlinks and Third-Party Sites</h2>
        <p>
          6.1 The Site may contain hyperlinks or references to third party
          advertising and websites other than the Site. Any such hyperlinks or
          references are provided for your convenience only. We have no control
          over third party advertising or websites and accept no legal
          responsibility for any content, material or information contained in
          them. The display of any hyperlink and reference to any third-party
          advertising or website does not mean that we endorse that third
          party’s website, products, or services. Your use of a third-party site
          may be governed by the terms and conditions of that third-party site
          and is at your own risk.
        </p>

        <h2>7. Accuracy of Information and Availability of the Site</h2>
        <p>
          7.1 We try to make sure that the Site is accurate, up-to-date, and
          free from bugs, but we cannot promise that it will be. Furthermore, we
          cannot promise that the Site will be fit or suitable for any purpose.
          Any reliance that you may place on the information on the Site is at
          your own risk.
        </p>

        <p>
          7.2 We may suspend or terminate access or operation of the Site at any
          time as we see fit.
        </p>
        <p>
          7.3 Any Content is provided for your general information purposes only
          and to inform you about us and our products and news, features,
          services, and other websites that may be of interest, but has not been
          tailored to your specific requirements or circumstances. It does not
          constitute technical, financial, or legal advice or any other type of
          advice and should not be relied on for any purposes. You should always
          use your own independent judgment when using our Site and its Content.
        </p>

        <p>
          7.4 While we try to make sure that the Site is available for your use,
          we do not promise that the Site will be available at all times or that
          your use of the Site will be uninterrupted.
        </p>

        <h2>8. Our Responsibility to You</h2>
        <p>
          8.1 If we breach these Terms or are negligent, we are liable to you
          for foreseeable loss or damage that you suffer as a result. By
          ‘foreseeable’ we mean that, at the time these Terms were formed, it
          was either clear that such loss or damage would occur, or you and we
          both knew that it might reasonably occur, as a result of something we
          did (or failed to do).
        </p>

        <p>
          8.2 We are not liable to you for any loss or damage that was not
          foreseeable, any loss or damage not caused by our breach or
          negligence, or any business loss or damage.
        </p>
        <p>
          8.3 Nothing in these terms excludes or limits our liability for any
          death or personal injury caused by our negligence, liability for fraud
          or fraudulent misrepresentation, or any other liability that the law
          does not allow us to exclude or limit.
        </p>

        <h2>9. Events Beyond Our Control</h2>
        <p>
          9.1 We are not liable to you if we fail to comply with these Terms
          because of circumstances beyond our reasonable control.
        </p>

        <h2>10. No Third-Party Rights</h2>
        <p>
          10.1 No one other than us or you has any right to enforce any of these
          Terms.
        </p>

        <h2>11. General</h2>
        <p>
          11.1 Where our Site contains links to other websites and resources
          provided by third parties, these links are provided for your
          information only. We have no control over the contents of those
          websites or resources.
        </p>
        <p>
          11.2 Any formal legal notices should be sent to us using the details
          set out on our Site.
        </p>
        <p>
          11.3 Failure by us to enforce a right does not result in waiver of
          such right. You may not assign or transfer your rights under these
          Terms of Use.
        </p>
        <p>
          11.4 If any part of these Terms of Use is found to be unenforceable as
          a matter of law, all other parts of these Terms of Use shall be
          unaffected. and shall remain in force.
        </p>

        <h2>12. Governing Law and Jurisdiction</h2>
        <p>
          12.1 These Terms make up the whole agreement between you and us in how
          you use our Site. If a court decides that a condition is not valid,
          the rest of the Terms will still apply.
        </p>
        <p>
          12.2 The laws of England and Wales apply to your use of our Site and
          these Terms. We control our Site from within the United Kingdom.
          However, you can get access to our Site from other places around the
          world. Although, these places may have different laws from the laws of
          England and Wales, by using our Site you agree that the laws of
          England and Wales will apply to everything relating to you using our
          Site and you agree to keep to these laws. We have the right to take
          you to court in the country you live in.
        </p>
        <h2>13. How to Contact Us</h2>
        <p>
          13.1 If you experience problems with our Site or would like to comment
          on it, please feel free to contact us by using the details set out on
          our Site.
        </p>
      </div>

      {/* Footer with full width */}
      <Footer />

      <ToastContainer />
    </div>
  );
};

export default TermsOfUse;
