import React, { useEffect } from "react";
import "./TradingTerms.css";
import NavbarLanding from "../../Components/NavbarLanding/NavbarLanding";
import Footer from "../Footer/Footer";
import { ToastContainer } from "react-toastify";

const TradingTerms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="trading-page">
      <NavbarLanding />
      <div className="TradingTerms-page">
        <h1>Trading Terms</h1>
        <p>
          (applicable only if the Site can be used to purchase goods or
          services)
        </p>

        <h2>1. Introduction</h2>
        <p>
          <strong>1.1</strong> The terms contained in this document (Trading
          Terms) apply to all transactions for the purchase of products and
          services (each a “Service” or, two or more, the “Services”) from the
          Site. By ordering any Services from our Site, you are indicating your
          acceptance to be bound by these Trading Terms. They form a legal
          agreement between you and us and can only be amended with our consent.
          You can print a copy of these Trading Terms by selecting the print
          option from the “File” menu of your browser.
        </p>

        <p>
          <strong>1.2</strong> In the event that the Site contains separate
          terms of trading linked to via the Site homepage, in the case of
          conflict between those terms of trading and these Trading Terms, the
          terms of trading shall prevail over these Trading Terms.
        </p>

        <p>
          <strong>1.3</strong> We reserve the right to change these Trading
          Terms from time to time without prior notice to you, provided that any
          such change will not affect any purchases you have made before the
          change is implemented.
        </p>

        <h2>2. Ordering</h2>
        <p>
          <strong>2.1</strong> You may place an order to purchase a Service
          advertised for sale on our Site by following the onscreen prompts
          after clicking on the item you are interested in. You will have an
          opportunity to check and correct any input errors in your order up
          until the point at which you submit your order by clicking the “Place
          Order” button on the checkout page.
        </p>

        <p>
          <strong>2.2</strong> All orders placed by you are subject to
          acceptance by us. We may choose not to accept your order for any
          reason and will not be liable to you or to anyone else in those
          circumstances.
        </p>

        <p>
          <strong>2.3</strong> After submitting an order to us, you will be sent
          an order acknowledgement email with your order reference and details
          of the products you have ordered. Acceptance of your order and the
          formation of the contract between us will take place when we send you
          an email confirming that the products you have ordered are being
          dispatched to you, unless we have notified you that we do not accept
          your order or you have already cancelled it in accordance with the
          provisions below (see Cancellations and Returns).
        </p>

        <p>
          <strong>2.4</strong> If your order includes Service(s) which are not
          available from stock, we will contact you by email or by telephone to
          ask you how you wish to proceed. You will have the option to wait
          until the Service(s) are available from stock, or cancel your order.
        </p>

        <h2>3. Prices and payment</h2>
        <p>
          <strong>3.1</strong> The prices of Services advertised for sale on our
          Site are as set out on our Site. All prices are in pounds (£)
          sterling, exclusive of VAT, and shipping charges. Shipping charges
          will be added to the total amount due once you have selected a
          shipping service from the available option as set out in Shipping
          Information.
        </p>

        <p>
          <strong>3.2</strong> Prices may change at any time prior to (but not
          after) acceptance of your order.
        </p>

        <p>
          <strong>3.3</strong> We cannot accept your order until you have paid
          for it in full.
        </p>

        <p>
          <strong>3.4</strong> In the unlikely event that the price of an item
          has been incorrectly advertised on our Site, we will contact you by
          email or telephone to ask whether you wish to proceed with the order
          at the correct price. If you are not happy to proceed, or we are
          unable to obtain your instructions, we will cancel the order. Unless
          we have already confirmed dispatch of your order, we will not be
          obliged to supply Services at the incorrect price.
        </p>

        <h2>4. Delivery/ Shipping</h2>
        <p>
          <strong>4.1</strong> Subject to availability, we will use all
          reasonable endeavours to deliver the Services you have ordered as soon
          as possible after your order is accepted by us.
        </p>

        <p>
          <strong>4.2</strong> We will deliver directly to the address specified
          in your order.
        </p>

        <p>
          <strong>4.3</strong> The precise timing of a delivery cannot be
          specified. Certain deliveries may require a signature to confirm
          receipt.
        </p>

        <p>
          <strong>4.4</strong> Once delivered, the Services ordered will become
          your property and your responsibility and, except in relation to
          Services that are damaged or faulty when delivered or have been
          incorrectly delivered, we will not accept any liability for their
          loss, damage or destruction after they have been delivered.
        </p>

        <h2>5. Cancellations and returns</h2>
        <p>
          <strong>5.1</strong> Unless one of the exceptions listed in Clause 5.4
          below applies, you may cancel your order (or any part of it) without
          giving any reason within fourteen (14) days. The cancellation period
          will expire fourteen (14) days from the day on which you acquire, or a
          third party other than the carrier and indicated by you acquires,
          physical possession of the goods. To exercise the right to cancel, you
          must notify us, giving us your full name, address, and order reference
          (if any) or, alternatively, by returning the Services, in accordance
          with the provisions below (see Clause 5.4).
        </p>

        <p>
          <strong>5.2</strong> To meet the cancellation deadline, it is
          sufficient for you to send your communication concerning your exercise
          of the right to cancel before the cancellation period has expired.
        </p>

        <p>
          <strong>5.3</strong> If you cancel this contract, we will reimburse to
          you all payments received from you, including the cost of
          delivery/shipping (except for the supplementary costs arising if you
          chose a type of delivery/shipping other than the least expensive type
          of standard delivery/shipping offered by us). We will make the
          reimbursement using the same means of payment as you used for the
          initial transaction, unless you have expressly agreed otherwise; in
          any event, you will not incur any fees as a result of the
          reimbursement. We may withhold the reimbursement until we have
          received the goods back or you have supplied evidence of having sent
          back the goods, whichever is the earliest. Alternatively, you may ask
          us to substitute a Service, rather than provide you with a refund, but
          we can only do that if the Service you wish to substitute is of
          equivalent value to the order you are cancelling.
        </p>

        <p>
          <strong>5.4</strong> You may not cancel your order if:
        </p>
        <ul>
          <li>
            you have taken any audio and/or visual recordings or computer
            software out of the sealed packaging in which they were delivered to
            you;
          </li>
          <li>
            the Services consist of perishable items or have been sealed for
            health protection or hygiene purposes, once these have been unsealed
            after you receive them;
          </li>
          <li>
            the Services have been customised or made to your own
            specifications;
          </li>
          <li>any Services you have started to download or stream; or</li>
          <li>
            any Services which become mixed inseparably with other items after
            their delivery, unless such Services were damaged or faulty when
            delivered to you or have been incorrectly delivered.
          </li>
        </ul>

        <p>
          <strong>5.5</strong> All such Services should be returned within
          fourteen (14) days of you cancelling your order and, in any event, no
          later than twenty-eight (28) days after the Service(s) have been
          delivered to you, in accordance with the following process:
        </p>
        <ul>
          <li>
            Pack the returns parcel securely, ensuring you include the returns
            note that will have been included in the package in which your order
            was delivered, and attach the returns address label that will also
            have been included.
          </li>
          <li>
            Return the parcel to us, we suggest, either by courier or by
            recorded delivery mail or another form of certified mail. We advise
            that you take out enough postal insurance to cover the value of the
            contents.
          </li>
        </ul>

        <p>
          <strong>5.6</strong> Our policy on cancellations and returns does not
          affect your statutory legal rights.
        </p>

        <h2>6. Faulty Services</h2>
        <p>
          <strong>6.1</strong> If any Service you purchase is damaged or faulty
          when delivered to you we may offer a repair, exchange or refund as
          appropriate, in accordance with your legal rights. If you believe a
          Service is faulty, you should notify us to arrange for the return of
          the Service(s).
        </p>
        <p>
          <strong>6.2</strong> Our policy on faulty Services does not affect
          your statutory legal rights.
        </p>
        <h2>7. Service Information</h2>
        <p>
          <strong>7.1</strong> We have taken reasonable steps to display as
          accurately as possible the colours and other detailing of our Services
          in the images that appear on our Site. However, as the actual colours
          and detailing you see onscreen will depend on your monitor, we cannot
          guarantee that your monitor’s display of any colour or other detailing
          will exactly reflect the colour or detailing of the Service(s) upon
          delivery.
        </p>

        <p>
          <strong>7.2</strong> From time to time, our stores may run special or
          local promotions which may not be available online, or we may offer
          special promotions online that are not available in our stores. Please
          note that, unless expressly stated on our Site, purchases made online
          do not qualify for points under any loyalty card scheme or discounts
          under any discount card scheme which we may operate in our stores.
        </p>

        <p>
          <strong>7.3</strong> Any information on our Site regarding sizing of
          Services is included as a guide only. If you are in any doubt as to
          the size of any Service you require, we recommend that you contact us
          prior to placing an order.
        </p>

        <h2>8. Orders for delivery outside the United Kingdom</h2>
        <p>
          <strong>8.1</strong> If you choose to access our Site from outside the
          United Kingdom, you are responsible for complying with local laws, if
          and to the extent that they are applicable. We do not represent or
          warrant that any Service(s) on our Site is appropriate for use or
          available in locations outside of the United Kingdom, or that it
          complies with any legal or regulatory requirements of such other
          locations.
        </p>
        <p>
          <strong>8.2</strong>Please contact us before ordering Services for
          delivery outside the United Kingdom. We may refuse to accept your
          order should you not contact us before ordering. Subject to us
          agreeing to and being able to deliver the Services outside the United
          Kingdom, as they may be subject to import duties and taxes. You will
          be responsible for these. We have no control over such charges and
          cannot advise you as to what they may be, although your local customs
          office may be able to assist. For deliveries outside the United
          Kingdom, you will be regarded as the importer and it is your
          responsibility to comply with all laws and regulations of the country
          in which the Services are to be delivered. Cross-border deliveries may
          be subject to opening and inspection by customs authorities.
        </p>

        <h2>9. Security</h2>
        <p>
          <strong>9.1</strong> We understand that you may have concerns about
          security on the internet. Our Site uses a secure server in our online
          ordering process to protect your personal information.
        </p>

        <p>
          <strong>9.2</strong> When you proceed to the checkout, before you are
          prompted to complete your billing and shipping address, your browser
          will go into secure mode. Data relating to your order and your
          personal and payment card details will all pass to our server in an
          encrypted format. As soon as you have finished ordering you will exit
          secure mode. As an additional protection for you, our system is
          designed so that you cannot place an order until you are safely within
          secure mode.
        </p>

        <p>
          <strong>9.3</strong> We recommend that you do not communicate your
          payment card details to anyone, including us, by email. We cannot be
          responsible for any losses you may incur in transmitting information
          to us by internet link or by email. Any such loss shall be entirely
          your responsibility.
        </p>

        <p>
          <strong>9.4</strong> If you have any additional queries about
          security, please contact us.
        </p>

        <h2>10. Our liability</h2>
        <p>
          <strong>10.1</strong> We will not be liable to you where performance
          of any of our obligations is prevented or restricted by any
          circumstance or cause beyond our reasonable control.
        </p>

        <p>
          <strong>10.2</strong> You are responsible for the use you make of the
          Service(s) you order. To the extent not prohibited by law, we accept
          no liability for any loss or damage which is not reasonably
          foreseeable or for any business loss (which includes loss of profits,
          contracts, goodwill, business interruption, loss of business or
          opportunity and other similar losses).
        </p>

        <p>
          <strong>10.3</strong> We accept liability for death or personal injury
          caused by our negligence and responsibility for fraudulent
          misrepresentation and any other liability that cannot, under English
          law, be excluded.
        </p>

        <p>
          <strong>10.4</strong> Nothing in this section or elsewhere in our
          Trading Terms affects your statutory legal rights.
        </p>

        <h2>11. Personal Data</h2>
        <p>
          <strong>11.1</strong> We will only use your personal information in
          accordance with our Privacy & Cookie Policy. Please take the time to
          read our Privacy & Cookie Policy, as it includes important terms which
          apply to you.
        </p>

        <h2>12. General</h2>
        <p>
          <strong>12.1</strong> Failure by us to enforce a right does not result
          in waiver of such right. You may not assign or transfer your rights
          under these Trading Terms.
        </p>

        <p>
          <strong>12.2</strong> If any part of these Trading Terms is found to
          be unenforceable as a matter of law, all other parts of these Trading
          Terms shall be unaffected and shall remain in force.
        </p>

        <p>
          <strong>12.3</strong> You and we agree that English law applies to
          these Trading Terms and that any dispute between us arising out of or
          in connection with these Trading Terms will only be dealt with by the
          English courts, unless you are resident in another part of the United
          Kingdom, in which case the applicable law of that part of the United
          Kingdom will apply and any dispute will be brought before the courts
          there. We are required by law to inform you that purchases can be
          concluded in English only and that no public filing requirements
          apply.
        </p>

        <p>
          <strong>12.4</strong> A person who is not a party to this agreement
          shall not have any rights under the Contracts (Rights of Third
          Parties) Act 1999 to enforce any term of this agreement.
        </p>

        <p>
          <strong>12.5</strong> Even if we delay in enforcing this agreement, we
          can still enforce it later. If we do not insist immediately that you
          do anything you are required to do under these Trading Terms, or if we
          delay in taking steps against you in respect of your breaking this
          agreement, that will not mean that you do not have to do those things
          and it will not prevent us taking steps against you at a later date.
          For example, if you miss a payment and we do not chase you but we
          continue to provide the Services, we can still require you to make the
          payment at a later date.
        </p>

        <h2>13. How to contact us</h2>
        <p>
          <strong>13.1</strong>Please feel free to contact us using the details
          set out on our Site.
        </p>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default TradingTerms;
