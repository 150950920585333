import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import email from "../../Assets/footer-assets/icons8-email-clr.png";
import location from "../../Assets/footer-assets/icons8-location-clr.png";
import phone from "../../Assets/footer-assets/icons8-phone1-clr.png";

const Footer = () => {
  const handlePhoneClick = () => {
    window.location.href = "tel:+44-7590662656";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:jobsmaritimegpt@gmail.com";
  };

  const handleLocationClick = () => {
    window.location.href =
      "https://www.google.com/maps?q=110+Hilmanton+Reading+RG6+4HJ,+UK";
  };

  return (
    <div className="footer" id="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <p>JOBS MARITIME</p>
        </div>
        <hr />
        <br />
        <p className="Address">
          Great Future Tech LTD, registered as a limited company in England and
          Wales under company number: 15656509. Registered Company Address: 110
          Hilmanton, Lower Earley, Reading, England, RG6 4HJ
        </p>

        <div className="footer-links">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>

            <li>
              <Link to="/feedback">Feedback</Link>
            </li>
            <li>
              <Link to="/terms-of-use">Terms Of Use</Link>
            </li>
            <li>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/tradingterms">Trading Terms</Link>
            </li>
          </ul>
        </div>
        <div className="footer-contact">
          <ul>
            <li onClick={handlePhoneClick}>
              <img src={phone} alt="Phone" /> +44-7590662656
            </li>
            <li onClick={handleEmailClick}>
              <img src={email} alt="Email" /> jobsmaritimegpt@gmail.com
            </li>
            <li onClick={handleLocationClick}>
              <img src={location} alt="Location" /> 110 Hilmanton Reading RG6
              4HJ, UK
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <p className="footer-copywrite">
        Copyright © {new Date().getFullYear()} Jobs-Maritime. All rights
        reserved.
      </p>
    </div>
  );
};

export default Footer;
