// import React, { useState } from "react";
// import { motion } from "framer-motion";
// import "./ChatbotModal.css";

// const ChatbotModal = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [messages, setMessages] = useState([
//     { content: "Hello! How can I assist you today?", type: "bot" },
//   ]);
//   const [inputMessage, setInputMessage] = useState("");

//   const toggleModal = () => setIsOpen(!isOpen);

//   const handleSendMessage = async () => {
//     if (!inputMessage.trim()) return;

//     // Add user message
//     setMessages((prev) => [...prev, { content: inputMessage, type: "user" }]);

//     try {
//       const response = await fetch("http://localhost:8000/jobs-chat", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ message: inputMessage }),
//       });

//       const data = await response.json();
//       console.log(data);

//       if (data.type === "jobs") {
//         const jobsContent = data.content
//           .map(
//             (job) =>
//               `<strong>${job["Job Title"]}</strong><br><a href="${job["Job Link"]}" target="_blank">View Job</a>`
//           )
//           .join("<br><br>");
//         console.log(jobsContent);
//         setMessages((prev) => [
//           ...prev,
//           {
//             content: (
//               <div
//                 dangerouslySetInnerHTML={{
//                   __html: `
//             Here are some jobs you might be interested in:<br><br>${jobsContent}
//           `,
//                 }}
//               />
//             ),
//             type: "bot",
//           },
//         ]);
//       } else {
//         setMessages((prev) => [
//           ...prev,
//           { content: data.content, type: "bot" },
//         ]);
//       }
//     } catch (error) {
//       setMessages((prev) => [
//         ...prev,
//         {
//           content: "Sorry, there was an error processing your request.",
//           type: "bot",
//         },
//       ]);
//     }

//     setInputMessage("");
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       handleSendMessage();
//     }
//   };

//   return (
//     <div className="chatbot-container">
//       {!isOpen ? (
//         <button onClick={toggleModal} className="open-chatbot-button">
//           Chat with Xena
//         </button>
//       ) : (
//         <motion.div
//           initial={{ opacity: 0, scale: 0.9 }}
//           animate={{ opacity: 1, scale: 1 }}
//           exit={{ opacity: 0, scale: 0.9 }}
//           className="chatbot-modal"
//         >
//           <div className="chatbot-header">
//             <h2>Chat with Xena</h2>
//             <button onClick={toggleModal} className="chatbot-close-button">
//               X
//             </button>
//           </div>

//           <div className="chatbot-messages">
//             {messages.map((msg, index) => (
//               <div
//                 key={index}
//                 className={`bot-message ${
//                   msg.type === "user" ? "user-message" : ""
//                 }`}
//               >
//                 {msg.content}
//               </div>
//             ))}
//           </div>

//           <div className="chatbot-input">
//             <input
//               type="text"
//               placeholder="Type your message..."
//               className="input-box"
//               value={inputMessage}
//               onChange={(e) => setInputMessage(e.target.value)}
//               onKeyPress={handleKeyPress}
//             />
//             <button className="send-button" onClick={handleSendMessage}>
//               Send
//             </button>
//           </div>
//         </motion.div>
//       )}
//     </div>
//   );
// };

// export default ChatbotModal;

import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./ChatbotModal.css";

const botUrl = process.env.REACT_APP_API_URL;

const ChatbotModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { content: "Hello! How can I assist you today?", type: "bot" },
  ]);
  const [inputMessage, setInputMessage] = useState("");
  const [hasStartedChatting, setHasStartedChatting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [conversationContext, setConversationContext] = useState([]);

  const jobSuggestions = [
    "Chief Engineer Jobs",
    "Marine Engineer Jobs",
    "Deck Officer Jobs",
    "Naval Architect Jobs",
    "Port Operations Manager Jobs",
    "Technician Jobs",
  ];

  // Job-related keywords to identify job search queries
  const jobKeywords = [
    "job",
    "career",
    "position",
    "employment",
    "vacancy",
    "opening",
    "hire",
    "hiring",
    "work",
    "opportunity",
    "maritime",
    "marine",
    "ship",
    "vessel",
    "boat",
    "engineer",
    "engineering",
    "naval",
    "deck",
    "officer",
    "technician",
    "port",
    "offshore",
    "sailor",
  ];

  const toggleModal = () => setIsOpen(!isOpen);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isOpen) {
        toggleModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Function to check if a message is job-related
  const isJobRelated = (message) => {
    const lowercaseMessage = message.toLowerCase();
    return jobKeywords.some((keyword) => lowercaseMessage.includes(keyword));
  };

  // Add to the conversation context for maintaining history
  const updateConversationContext = (userMessage, botResponse) => {
    // Keep only the last 10 exchanges to avoid token limits
    const newContext = [
      ...conversationContext,
      { role: "user", content: userMessage },
      {
        role: "assistant",
        content:
          typeof botResponse === "string" ? botResponse : "Response provided",
      },
    ].slice(-10);

    setConversationContext(newContext);
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const userMessage = inputMessage.trim();
    setMessages((prev) => [...prev, { content: userMessage, type: "user" }]);
    setInputMessage("");
    setIsLoading(true);
    setHasStartedChatting(true);

    try {
      // Fetch User's IP for tracking/analytics
      let userIp = "unknown";
      try {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        if (ipResponse.ok) {
          const ipData = await ipResponse.json();
          userIp = ipData.ip;
        }
      } catch (error) {
        console.log("Could not fetch IP, continuing with request");
      }

      // For job-related queries, use the jobs-chat endpoint
      if (isJobRelated(userMessage)) {
        const response = await fetch(`${botUrl}/jobs-chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: userMessage,
            ip: userIp,
            context: conversationContext,
          }),
        });

        if (!response.ok) {
          throw new Error(`Server responded with status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Response from jobs backend:", data);

        // Process different response types
        if (data.type === "jobs") {
          // Handle job listings
          handleJobResponse(data.content);
          // Update conversation context with a summarized version
          updateConversationContext(userMessage, "Job listings provided");
        } else {
          // Handle informational responses
          handleInfoResponse(data.content);
          // Update context with the actual response content
          updateConversationContext(
            userMessage,
            typeof data.content === "string"
              ? data.content
              : "Information provided about jobs"
          );
        }
      } else {
        // For all non-job queries, use ChatGPT with context
        const chatgptResponse = await fetch(`${botUrl}/chatgpt`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: userMessage,
            ip: userIp,
            context: conversationContext,
          }),
        });

        if (!chatgptResponse.ok) {
          throw new Error(
            `ChatGPT responded with status: ${chatgptResponse.status}`
          );
        }

        const chatData = await chatgptResponse.json();
        console.log("Response from ChatGPT:", chatData);

        setMessages((prev) => [
          ...prev,
          { content: chatData.response, type: "bot" },
        ]);

        // Update conversation context
        updateConversationContext(userMessage, chatData.response);
      }
    } catch (error) {
      console.error("Error in chat communication:", error);
      const errorMessage =
        "Sorry, there was an error processing your request. Please try again later.";
      setMessages((prev) => [...prev, { content: errorMessage, type: "bot" }]);
      updateConversationContext(userMessage, errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleJobResponse = (jobsData) => {
    if (!Array.isArray(jobsData) || jobsData.length === 0) {
      setMessages((prev) => [
        ...prev,
        {
          content:
            "I couldn't find jobs matching your criteria. Would you like to try a different search?",
          type: "bot",
        },
      ]);
      return;
    }

    const jobsContent = jobsData
      .map(
        (job) =>
          `<div class="job-listing">
          <strong>${job["Job Title"]}</strong><br>
          ${job["Company"] ? `<span>${job["Company"]}</span><br>` : ""}
          ${job["Location"] ? `<span>${job["Location"]}</span><br>` : ""}
          <a href="${
            job["Job Link"]
          }" target="_blank" rel="noopener noreferrer">View Job</a>
        </div>`
      )
      .join("");

    setMessages((prev) => [
      ...prev,
      {
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: `<div class="jobs-container">
                <p>Here are some jobs that match your criteria:</p>
                ${jobsContent}
              </div>`,
            }}
          />
        ),
        type: "bot",
      },
    ]);
  };

  const handleInfoResponse = (content) => {
    let botResponse;

    // Check if response is an array of content items
    if (Array.isArray(content)) {
      // Filter out duplicate content
      const uniqueResponses = [];
      const seenContent = new Set();

      content.forEach((item) => {
        // Only add unique content
        if (item && item.Content && !seenContent.has(item.Content)) {
          seenContent.add(item.Content);
          uniqueResponses.push(item);
        }
      });

      // If we have unique responses, display them
      if (uniqueResponses.length > 0) {
        botResponse = (
          <div className="info-response">
            {uniqueResponses.map((item, index) => (
              <div key={index} className="response-item">
                <p>{item.Content}</p>
                {item.Source && (
                  <p className="source-info">
                    <small>Source: {item.Source}</small>
                  </p>
                )}
              </div>
            ))}
          </div>
        );
      } else {
        botResponse = "I don't have specific information on that topic.";
      }
    }
    // Single object with Content/Source
    else if (
      typeof content === "object" &&
      content !== null &&
      content.Content
    ) {
      botResponse = (
        <div className="info-response">
          <p>{content.Content}</p>
          {content.Source && (
            <p className="source-info">
              <small>Source: {content.Source}</small>
            </p>
          )}
        </div>
      );
    }
    // String response
    else if (typeof content === "string") {
      botResponse = content;
    }
    // Fallback for unexpected formats
    else {
      botResponse = "I received information but couldn't format it properly.";
    }

    setMessages((prev) => [...prev, { content: botResponse, type: "bot" }]);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputMessage(suggestion);
    setHasStartedChatting(true);
    // Focus on input after selecting a suggestion
    document.querySelector(".input-box").focus();
  };

  // Get current suggestions based on conversation context
  const getCurrentSuggestions = () => {
    // Always return job suggestions now that we've removed general suggestions
    return jobSuggestions;
  };

  return (
    <div className="chatbot-container">
      {!isOpen ? (
        <button onClick={toggleModal} className="open-chatbot-button">
          Chat with Xena
        </button>
      ) : (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="chatbot-modal"
        >
          <div className="chatbot-header">
            <h2>Chat with Xena</h2>
            <button onClick={toggleModal} className="chatbot-close-button">
              X
            </button>
          </div>

          <div className="chatbot-messages">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`bot-message ${
                  msg.type === "user" ? "user-message" : ""
                }`}
              >
                {msg.content}
              </div>
            ))}
            {isLoading && <div className="loading-dots">Typing</div>}
            <div ref={messagesEndRef} />
          </div>

          {!hasStartedChatting && (
            <div className="job-suggestions">
              {jobSuggestions.map((job, index) => (
                <button
                  key={index}
                  className="job-suggestion-button"
                  style={{
                    backgroundColor: [
                      "#D4ECDD",
                      "#A0C4FF",
                      "#FFABAB",
                      "#FFC3A0",
                      "#FFD3B6",
                      "#FFAAA5",
                    ][index % 6],
                  }}
                  onClick={() => {
                    setInputMessage(job);
                    setHasStartedChatting(true);
                  }}
                >
                  {job}
                </button>
              ))}
            </div>
          )}
          <div className="chatbot-input">
            <input
              type="text"
              placeholder="Type your message..."
              className="input-box"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button className="send-button" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default ChatbotModal;
