import React, { useState, useEffect } from "react";
import axios from "axios";
import validator from "validator";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instagram_icon from "../../Assets/instagram_icon.png";
import pinterest_icon from "../../Assets/pinterest_icon.png";
import whatsapp_icon from "../../Assets/whatsapp_icon.png";
import "./Feedback.css";
import NavbarLanding from "../../Components/NavbarLanding/NavbarLanding";
import Footer from "../Footer/Footer";

const apiUrl = process.env.REACT_APP_API_URL;

const Feedback = () => {
  const [values, setValues] = useState({ name: "", email: "", message: "" });
  const [emailTouched, setEmailTouched] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleBlur = (e) => {
    if (e.target.name === "email") {
      setEmailTouched(true);
      if (!validator.isEmail(values.email)) {
        toast.error("Provide a valid email!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  const submit = async () => {
    if (values.name.trim() === "" || values.message.trim() === "") {
      toast.error("All Fields are Required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (!validator.isEmail(values.email)) {
      toast.error("Please correct the errors before submitting", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      try {
        await axios.post(`${apiUrl}/api/feedback`, values);
        setValues({ name: "", email: "", message: "" });
        toast.success("Your feedback has been submitted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } catch (error) {
        console.error("Error submitting feedback:", error);
        toast.error("Error submitting feedback. Please try again.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  return (
    <div className="feedback-page">
      <NavbarLanding />
      <div className="feedback-container">
        <h1>CONTACT US</h1>
        <p>We are looking forward to hearing from you soon</p>
        <div className="feedback-contact-info-container">
          <ul className="feedback-contact-list">
            <li>
              <span className="feedback-contact-label">Email:</span>
              <span className="feedback-contact-info">
                jobsmaritimegpt@gmail.com
              </span>
            </li>
            <li>
              <span className="feedback-contact-label">Phone:</span>
              <span className="feedback-contact-info">+91-8130971696</span>
            </li>
            <li>
              <span className="feedback-contact-label">Address:</span>
              <span className="feedback-contact-info">
                110 Hilmanton, Reading, Berkshire, United Kingdom, RG6 4HJ
              </span>
            </li>
            <div className="feedback-icons-list">
              <li>
                <div className="feedback-footer-icons-container ">
                  <img src={instagram_icon} alt="Instagram icon" />
                </div>
              </li>
              <li>
                <div className="feedback-footer-icons-container ">
                  <img src={pinterest_icon} alt="Pinterest icon" />
                </div>
              </li>
              <li>
                <div className="feedback-footer-icons-container ">
                  <img src={whatsapp_icon} alt="WhatsApp icon" />
                </div>
              </li>
            </div>
          </ul>
          <div className="feedback-form-container">
            <div className="feedback-form-group">
              <label htmlFor="name">NAME</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                value={values.name}
                onChange={handleChange}
                className="feedback-input-boxes"
              />
            </div>
            <div className="feedback-form-group">
              <label htmlFor="email">EMAIL</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="feedback-input-boxes"
              />
            </div>
            <div className="feedback-form-group">
              <label htmlFor="message">MESSAGE</label>
              <textarea
                id="message"
                name="message"
                placeholder="Your Message"
                value={values.message}
                onChange={handleChange}
                className="feedback-input-boxes"
              ></textarea>
            </div>
            <button className="feedback-btn" onClick={submit}>
              DONE
            </button>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Feedback;
