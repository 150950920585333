import React, { useEffect } from "react";
import "./PrivacyPolicy.css";
import NavbarLanding from "../../Components/NavbarLanding/NavbarLanding";
import Footer from "../Footer/Footer";
import { ToastContainer } from "react-toastify";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy-page">
      {/* Navbar with full width */}
      <NavbarLanding />

      <div className="PrivacyPolicy-page">
        <div className="privacy-policy-content">
          <h1>Privacy & Cookie Policy</h1>
          <h2>1. Important notice</h2>
          <p>
            This Privacy Policy describes how we obtain and use your personal
            data, why we are allowed to do so by the law, who has access to your
            personal data and what your rights are. Please review it carefully.
            In the event that the Site contains a separate Privacy Statement
            linked to via the Site homepage, in the case of conflict between
            that Privacy Statement and this Privacy Policy, the Privacy
            Statement shall prevail over this Privacy Policy We take your
            privacy seriously and use your personal data as further explained in
            this Privacy Policy. We are the “controller” of the personal data
            you provide to us.
            <br />
            <strong>
              You have the right to object to us processing your personal data
              for our legitimate business interests or for direct marketing
              purposes (including any related profiling). For more information
              about your rights and how you can exercise them, please see the
              section Your rights.
            </strong>
          </p>
          <h2>2. Personal Data we may collect and why</h2>
          <p>
            Personal data is any information which identifies you personally
            whether directly (for example, your name) or indirectly (for
            example, information about your use of our products and services).
            We may collect the following data about you:
            <li>
              Contact details: your name, email address, and telephone number so
              that we can contact you in response to an enquiry you make via our
              Site or in relation to the products and services that we have from
              time to time agreed to provide to you;{" "}
            </li>
            <li>
              Correspondence: we collect any additional personal data you may
              provide to us from time to time if you contact us by email, letter
              or telephone, through our Site, by submitting a comment on our
              Site, or by any other means;
            </li>
            <li>
              Survey responses: information from surveys that we use for
              research purposes, if you choose to respond to them;
            </li>
            <li>
              Transaction details: we or our third party providers will collect
              information relating to transactions you carry out through our
              Site and for the purposes of fulfilling your orders;
            </li>
            <li>
              Details of visits to the Sites: details of your visits to our
              Site, including, but not limited to, traffic data, location data,
              weblogs and other communication data, whether this is required for
              our own billing purposes or otherwise and the resources that you
              access.
            </li>
          </p>
          <h2>3. Cookies</h2>
          <p>
            <strong>What are Cookies?</strong>
          </p>
          <p>
            We collect information about your use of our Site through cookies.
            Cookies are information files stored on your computer, tablet, or
            smartphone that help websites remember who you are and information
            about your visit. Cookies can help to display the information on our
            Site in a way that matches your interests. Most major websites use
            cookies.
          </p>
          <p>
            <strong>a. What cookies are used on this Site?</strong>
          </p>
          <p>
            The cookies we and our business partners use on our Site are broadly
            grouped into the following categories:
          </p>
          <p>
            <strong>• Essential –</strong> Some of the cookies on our Site are
            essential for us to be able to provide you with a service you have
            requested. An example of this could be a cookie used to enable you
            to log into your account on our Site or which allows communication
            between your browser and our Site. Our cookie preference cookie
            described in the section “How can I reject or opt out of receiving
            cookies?” is also an essential cookie. You may not be able to use
            our Site without these cookies.
          </p>
          <p>
            <strong>• Analytics –</strong> We use analytics cookies to help us
            understand how users engage with our Site. An example is counting
            the number of different people coming to our Site or using a
            particular feature, rather than the total number of times the site
            or feature is used. Without this cookie, if you visited our Site
            once each week for three weeks, we would count you as three separate
            users. We would find it difficult to analyze how well our Site was
            performing and improve it without these cookies.
          </p>
          <p>
            <strong>• User Cookies –</strong> We use cookies to improve your
            experience by remembering your preferences so we know how you like
            to use our Site. Examples of this would be remembering you so that
            you are served with the same content or to remember you when you
            come back to our Site.
          </p>
          <p>
            <strong>• Social Sharing –</strong> We use third-party cookies to
            allow you to share content directly on social networking/sharing
            sites like Facebook, Twitter, or Google+. Examples would be if you
            wanted to “like” or “tweet” about us or our products or services.
            Please see our “Third Party Cookies” section below for more details.
          </p>
          <p>
            <strong>• Interest-Based Advertising –</strong> You will have
            noticed that when you visit websites, you will be shown adverts for
            products and services you may wish to buy. The money made by website
            owners for showing third-party adverts on their websites often pays
            for the cost of running the website and therefore usually allows you
            to use the website without having to pay a registration or usage
            fee. To try and ensure that the adverts you see are relevant to you,
            third-party cookies may be used to collect information about the
            types of things that interest you, for example, websites you visit
            and the geography that you are based in. Having these cookies does
            not increase the number of adverts you will be shown, but simply
            makes the adverts you see more relevant. Please see our “Third Party
            Cookies” section below for more details.
          </p>
          <p>
            <strong>
              b. How can I reject or opt out of receiving cookies?
            </strong>
          </p>
          <p>
            You may refuse to accept cookies by activating the setting on your
            browser which allows you to refuse the setting of cookies. However,
            if you select this setting, you may be unable to access certain
            parts of our Site. Unless you have adjusted your browser setting so
            that it will refuse cookies, our system will issue cookies when you
            use our Site. The “Help” menu of the toolbar on most browsers will
            tell you how to prevent your browser from accepting new cookies, how
            to have the browser notify you when you receive a new cookie, or how
            to disable cookies altogether. For more information about cookies
            and how to stop cookies from being installed or how to delete
            existing cookies from your hard drive, visit the following website:{" "}
            <a href="http://www.allaboutcookies.org" target="_blank">
              http://www.allaboutcookies.org
            </a>
            .
          </p>
          <p>
            <strong>c. Third-party cookies</strong>
          </p>
          <p>
            Some of the cookies described in the “What Cookies are used on our
            Site” section above are stored on your machine by third parties when
            you use our Site. Third parties may also read cookies on your
            browser to collect information or to serve content or advertisements
            to you. We have no control over these cookies or how the third
            parties use them. They are used to allow that third party to provide
            a service to us, for example, analytics. For more information on
            these cookies and how to disable them, please see:
          </p>
          <p>
            •{" "}
            <a href="http://www.youronlinechoices.com" target="_blank">
              Internet Advertising Bureau website
            </a>{" "}
            where you will be able to opt out of receiving Interest-Based
            Advertising cookies from some of the third parties listed below.
          </p>
          <p>
            • If you want to know more about how cookies work and how to manage
            or delete them, visit the{" "}
            <a href="https://www.w3.org/" target="_blank">
              World Wide Web Consortium’s website
            </a>
            .
          </p>
          {/* <h2>4. How we use your personal data</h2> */}
          <h2>4. How We Use Your Personal Data</h2>
          <h3>
            To provide you with the products and services you have requested
          </h3>
          <p>
            We use your personal data to accept you as a new or returning
            customer and to provide you with the products and services you have
            requested in accordance with the Trading Terms or Terms of Trading.
          </p>
          <h3>
            To send you service communications, including in relation to changes
            to our Trading Terms or Terms of Trading
          </h3>
          <p>
            We use the contact details you have provided to communicate with you
            about the products and services we provide, including informing you
            about major changes to those products and services or to the Trading
            Terms or Terms of Trading.
          </p>
          <h3>Direct marketing (including by third parties)</h3>
          <p>
            If you have provided your consent or we otherwise have the right to
            do so, we may use your contact details to send you direct marketing
            and keep you informed of promotional offers via email, SMS, post, or
            telephone regarding our products and services.
          </p>
          <p>
            You can unsubscribe from our direct marketing at any time by
            clicking the “Unsubscribe” link in any of our emails or by
            contacting us.
          </p>
          <p>
            Our trusted business partners may also use your name, email address,
            postal address, and telephone number to inform you of similar
            products, services, and promotional offers. We will only share your
            personal data with our partners where you have provided consent. You
            can unsubscribe at any time by clicking the “Unsubscribe” link in
            their emails or by contacting us.
          </p>
          <h3>
            To track your usage of our website, communications, products, and
            services
          </h3>
          <p>
            We use cookies and similar technologies to track your activity on
            our Site, enabling us to provide important features and
            functionality, monitor its usage, and enhance your experience with
            personalized content.
          </p>
          <h3>To provide and improve customer support</h3>
          <p>
            We use your personal data to improve our customer support services,
            including responding to your questions regarding our products and
            services.
          </p>
          <h3>To maintain our records and improve data accuracy</h3>
          <p>
            We process personal data to maintain and administer our internal
            records, ensuring that the information we hold about you is kept up
            to date and accurate.
          </p>
          <h3>To respond to enquiries, complaints, and disputes</h3>
          <p>
            We use your personal data to handle any enquiries or complaints you
            have made or to resolve any disputes that arise during our provision
            of products and services to you.
          </p>
          <h3>
            To investigate, detect, and prevent fraud and comply with our legal
            obligations
          </h3>
          <p>
            In certain circumstances, we use your personal data to comply with
            legal obligations, including fraud detection, investigation, and
            prevention. This may involve providing your personal data to law
            enforcement agencies if requested.
          </p>
          <h2>5. Legal grounds for processing</h2>
          <p>
            Data protection law requires us to only process your personal data
            if we satisfy one or more legal grounds. These are set out in data
            protection law, and we rely on a number of different grounds for the
            processing we carry out. These are as follows:
          </p>
          <p>
            <strong>Consent</strong>
          </p>
          <p>
            In certain circumstances, we process your personal data after
            obtaining your consent to do so for the purposes of:
          </p>
          <ul>
            <li>
              Sending you marketing communications about our products and
              services;
            </li>
            <li>
              Sharing your name, email address, postal address, and telephone
              number with our trusted business partners so that they may market
              to you about their own similar products and services;
            </li>
            <li>Conducting marketing research;</li>
            <li>
              Obtaining your credit score so that we can establish the best
              possible payment terms we are able to offer to you.
            </li>
          </ul>
          <p>
            <strong>
              Necessary for the performance of a contract and to comply with our
              legal obligations
            </strong>
          </p>
          <p>
            It is necessary for us to process your basic contact details,
            payment details, and information about the business you represent
            for the performance of the Trading Terms or Terms of Trading between
            us. In particular, we rely on this legal ground to:
          </p>
          <ul>
            <li>Provide you with the products and services;</li>
            <li>
              Communicate with you about the products and services that we
              provide to you, including to let you know about major changes to
              those products and services or to the Trading Terms or Terms of
              Trading between us or to any related information;
            </li>
            <li>Provide and improve customer support;</li>
            <li>Notify you about changes to our service.</li>
          </ul>
          <p>
            If you choose not to give some or all of the aforementioned
            information to us, this may affect our ability to provide our
            products and services to you.
          </p>
          <p>
            In certain circumstances, we also use your personal data only to the
            extent required in order to enable us to comply with our legal
            obligations, including to detect, investigate, and prevent fraud.
          </p>
          <p>
            <strong>
              Necessary for the purposes of our legitimate business interests or
              those of a third party
            </strong>
          </p>
          <p>
            It is sometimes necessary to collect and use your personal data for
            the purposes of our legitimate interests as a business, which are
            to:
          </p>
          <ul>
            <li>
              Provide you with products and services that are as useful and
              beneficial as possible, including by personalizing our contact
              with you and making sure we tell you about all the offers that are
              relevant to you;
            </li>
            <li>
              Better understand our customer base so that we can improve our
              products and services and marketing activities (which could also
              benefit you);
            </li>
            <li>Comply with our contractual obligations to third parties;</li>
            <li>
              Develop and improve our Site to enhance the customer experience;
            </li>
            <li>
              Train our staff so that we can provide you with a better customer
              service;
            </li>
            <li>
              Respond to any enquiries or complaints you have made, or deal with
              any dispute which may arise in the course of us providing our
              products and services to you;
            </li>
            <li>
              Ensure that content from our Site is presented in the most
              effective manner for you and for your computer;
            </li>
            <li>
              Ensure effective operational management and internal
              administration of our business, document retention, compliance
              with regulatory guidance, and exercise or defense of legal claims.
            </li>
          </ul>
          <p>
            Where we think there is a risk that one of your interests or
            fundamental rights and freedoms may be affected, we will not process
            your personal data unless there is another legal ground for us to do
            so (either that we have obtained your consent to the processing or
            it is necessary for us to perform our contract with you or to comply
            with our legal obligations).
          </p>
          <h2>6. Who we share your personal data with</h2>
          <p>
            We may provide your personal data to our suppliers and service
            providers, including other companies in our group, who provide
            certain business services for us and act as “processors” of your
            personal data on our behalf. In addition, we may disclose your
            personal data if we are under a duty to disclose or share your
            personal data in order to comply with any legal obligation, or in
            order to protect the rights, property, or safety, of our business,
            our customers or others. This includes, in specific cases,
            exchanging information with other organisations for the purposes of
            fraud protection. In some cases, the personal data we collect from
            you may, for the purposes set out above, be transferred outside the
            European Economic Area (EEA) and such destinations may not have laws
            which protect your personal data to the same extent as in the EEA.
            We are required by data protection law to ensure that where we or
            our “processors” transfer your personal data outside of the EEA, it
            is treated securely and is protected against unauthorised access,
            loss or destruction, unlawful processing and any processing which is
            inconsistent with the purposes set out in this Privacy Policy.
          </p>
          <h2>7. How long we keep your personal data for</h2>
          <p>
            We retain your personal data for no longer than is necessary for the
            purpose(s) for which it was provided. What this means in practice
            will vary between different types of data. When determining the
            relevant retention periods, we take into account factors including:
          </p>
          <ul>
            <li>
              Legal obligation(s) under applicable law to retain data for a
              certain period of time;
            </li>
            <li>Statute of limitations under applicable law;</li>
            <li>Potential or actual disputes; and</li>
            <li>Guidelines issued by relevant data protection authorities.</li>
          </ul>
          <p>
            Otherwise, we securely erase your personal data from our systems
            when it is no longer needed.
          </p>
          <h2>8. Your rights</h2>
          <h2>Your Rights Regarding Your Personal Data</h2>
          <h3>1. Right to be informed</h3>
          <p>
            You have the right to be provided with clear, transparent, and
            easily understandable information about how we use your personal
            data and your rights. This is why we are providing you with the
            information in this Privacy Policy.
          </p>
          <h3>2. Right of access</h3>
          <p>
            You have the right to obtain access to your personal data (if we are
            processing it) and certain other information (similar to that
            provided in this Privacy Policy). This is so you are aware and can
            check that we are using your personal data in accordance with data
            protection law.
          </p>
          <h3>3. Right to rectification</h3>
          <p>
            You are entitled to have your personal data corrected if it is
            inaccurate or incomplete.
          </p>
          <h3>4. Right to erasure</h3>
          <p>
            This is also known as ‘the right to be forgotten’ and, in simple
            terms, enables you to request the deletion or removal of your
            personal data where there is no compelling reason for us to keep
            using it. This is not a general right to erasure; there are
            exceptions.
          </p>
          <h3>5. Right to restrict processing</h3>
          <p>
            You have the right to ‘block’ or suppress further use of your
            personal data in certain circumstances. When processing is
            restricted, we can still store your personal data, but may not use
            it further.
          </p>
          <h3>6. Right of data portability</h3>
          <p>
            You have the right to obtain and reuse your personal data in a
            structured, commonly used, and machine-readable format in certain
            circumstances. In addition, where certain conditions apply, you have
            the right to have such information transferred directly to a third
            party.
          </p>
          <h3>7. Right to object to processing</h3>
          <p>
            You have the right to object to us processing your personal data for
            our legitimate business interests or for direct marketing purposes
            (including in each case any related profiling).
          </p>
          <h3>8. Right to withdraw consent to processing</h3>
          <p>
            If you have given your consent to us to process your personal data
            for a particular purpose (for example, direct marketing), you have
            the right to withdraw your consent at any time (although if you do
            so, it does not mean that any processing of your personal data up to
            that point is unlawful).
          </p>
          <h3>
            9. Right to make a complaint to the data protection authorities
          </h3>
          <p>
            You have the right to make a complaint to the Information
            Commissioner’s Office (ICO) if you are unhappy with how we have
            handled your personal data or believe our processing of your
            personal data does not comply with data protection law.
          </p>
          <h2>9. How to contact us</h2>
          If you would like to exercise your data protection rights or if you
          are unhappy with how we have handled your personal data, please feel
          free to contact us by using the details set out on our Site.
          <h2>Contacting Us and Making a Complaint</h2>
          <p>
            If you would like to exercise your data protection rights or if you
            are unhappy with how we have handled your personal data, please feel
            free to contact us by using the details set out on our Site.
          </p>
          <p>
            If you’re not satisfied with our response to any enquiries or
            complaints or believe our processing of your personal data does not
            comply with data protection law, you can make a complaint to the
            Information Commissioner’s Office (ICO) by:
          </p>
          <ul>
            <li>
              Writing to: Information Commissioner’s Office, Wycliffe House,
              Water Lane, Wilmslow, Cheshire, SK9 5AF
            </li>
            <li>Calling: 0303 123 1113</li>
            <li>
              Submitting a message through the ICO’s website at:{" "}
              <a href="https://ico.org.uk" target="_blank">
                ico.org.uk
              </a>
            </li>
          </ul>
          <h2>10. Links to other websites</h2>
          <p>
            Our website may contain hyperlinks to websites owned and operated by
            third parties. This Privacy Policy does not apply to those other
            websites. We encourage you to read the privacy statements on the
            other websites you visit, as they will govern the use of any
            personal data you provide when visiting those websites. We do not
            accept any responsibility or liability for the privacy practices of
            such third party websites and your use of such websites is at your
            own risk.
          </p>
          <h2>11. Changes to this Privacy Policy</h2>
          <p>
            This Privacy Policy was last updated on 1 May 2018. This Privacy
            Policy may be updated from time to time, so you may want to check it
            each time you provide personal data to us.
          </p>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default PrivacyPolicy;
